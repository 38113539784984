import style from './ProfileData.module.css'

export const ProfileArrow = ({ opened }) => {
    const arrowClasses = style.profile_arrow
        + (opened ? ' ' + style.profile_arrow_opened : '')

    return (
        <svg className={arrowClasses} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6 9L12 15L18 9' strokeWidth='2' strokeLinecap='round' />
        </svg>
    )
}
