import style from './Alert.module.css'
import { AlertCloseBtn } from './AlertCloseBtn'

export const Alert = ({ message, onClose, showNewLeads }) => {
    return (
        <div className={style.alert} style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
            <div></div>
            <p onClick={showNewLeads} className={style.alert_message}>{message}</p>
            <AlertCloseBtn onClose={onClose} />
        </div>
    )
}
