import { animationOffset } from './animation'
import { visiblePages } from './pages'
import style from './Pagination.module.css'
import { PaginationButton } from './PaginationButton'
import { PaginationItem } from './PaginationItem'

export const Pagination = ({ setPage, page, pages }) => {
    const pageList = visiblePages(pages, page)
    const offset = animationOffset(pages, page)
    const paginationStyles = style.pagination + ' ' + style[`pagination_${offset}`]

    const handleNextPage = () => {
        if (page < pages) {
            setPage(page + 1)
        }
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }


    const renderedPageList = pageList.map((p, idx) => {
        return (
            <PaginationItem
                key={idx + Date.now()}
                selected={p === page}
                placeholder={!p}
                onClick={p ? () => setPage(p) : null}
            >
                {p || '...'}
            </PaginationItem>
        )
    })

    return (
        <div className={paginationStyles}>
            <PaginationButton left onClick={handlePrevPage} />

            {renderedPageList}
            
            <PaginationButton onClick={handleNextPage} />
        </div>
    )
}
