import axios from 'axios'

export const prod = true

export const PUBLIC_RECAPTCHA_KEY = '6Lc6F4wmAAAAAEnzteLioEUjXOsVqacfKYvuSeMX'

export const API_DOMAIN = prod
    ? 'https://api.ddxminicrm.ru/'
    : 'http://localhost:5005/'

export const API_STREAM = prod
    ? 'https://api.ddxminicrm.ru/stream'
    : 'http://localhost:5005/stream'

export const authApi = axios.create({
    withCredentials: prod,
    baseURL: API_DOMAIN
})