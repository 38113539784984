import style from './Export.module.css'
import { ExportButton } from './ExportButton'

export const Export = ({ ...props }) => {
    return (
        <div className={style.export}>
            <p {...props}>Экспорт в .xls</p>
            <ExportButton {...props} />
        </div>
    )
}
