import style from './Table.module.css'
import { TableRow } from './TableRow'

export const Table = ({ data = [], showData = {}, copyData = [], sortData = [], onEdit, onSort, sorted }) => {
    const columns = Object.keys(showData).length + (onEdit ? 1 : 0)

    const showSortButton = data?.length > 0

    return (
        <div className={style.table}>

            <TableRow
                rowData={showData}
                sortData={sortData}
                columns={columns}
                onEdit={onEdit}
                onSort={showSortButton && onSort}
                sorted={sorted}
            />

            {data.map((row, idx) => {
                return (
                    <TableRow
                        key={idx + Date.now()}
                        rowData={row}
                        showData={showData}
                        copyData={copyData}
                        onEdit={onEdit}
                        onSort={onSort}
                    />
                )
            })}
        </div>
    )
}