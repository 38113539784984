import { observer } from 'mobx-react-lite'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from 'src'
import { Block, Button, Centered, Checkbox, Container, Fieldset, Input, Label, List, Logo, Title } from 'src/components'


export const SelectEmpls = observer(() => {
    const navigate = useNavigate()
    
    const { workdayStore, authStore } = useContext(AppContext)
    const [ searchStr, setSearchStr ] = useState('')

    const filteredEmployees = workdayStore.available_employees
        .map(option => authStore.id === option.id ? ({ ...option, selected: true, name: option.name + ' (я)' }) : ({ ...option }))
        .filter(option => option.name.toLowerCase().includes(searchStr.toLowerCase().trim()))

    const canSubmit = workdayStore.available_employees.filter(e => e.selected).length

    const handleWorkdaySkip = () => workdayStore.skipWorkdayOpen()

    return (
        <Container>
            <Centered>
                <Block large>
                    <Logo small />
                    <Title medium>Выберите сотрудников:</Title>
                    <Fieldset>
                        <Input placeholder='Поиск...' search value={searchStr} onChange={setSearchStr} />
                        <Block colored>
                            <List>
                                {filteredEmployees.length ? filteredEmployees.map((empl, idx) => {
                                    return (
                                        <Checkbox
                                            onClick={empl.id === authStore.id ? () => {} : () => workdayStore.setSelected(empl)}
                                            checked={empl.selected}
                                            key={idx + Date.now()}
                                            disabled={empl.id === authStore.id}
                                        >
                                            {empl.name}
                                        </Checkbox>
                                    )}
                                ) : <Label>Сотрудник не найден</Label>}
                            </List>
                        </Block>
                    </Fieldset>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <Button disabled={!canSubmit} onClick={() => navigate('/workday/check_selected_employees')}>Продолжить</Button>
                        {authStore.isSuperAdmin && 
                            <Button onClick={handleWorkdaySkip} light>Пропустить</Button>}
                    </div>
                </Block>
            </Centered>
        </Container>
    )
})