import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { Arrow, Block, Button, CloseBtn, Fieldset, Form, Input, Label, Select, Tag, TagList, Title } from 'src/components'

const minDate = '2023-01-01'
const maxDate = new Date().toLocaleDateString().split('.').reverse().join('-')
const isFilterVisibleByDefault = true

export const LeadsFilter = observer(() => {
    const isVisible = JSON.parse(localStorage.getItem('crm_filter_block_visible'))
    const [isOpened, setIsOpened] = useState(isVisible !== null ? isVisible : isFilterVisibleByDefault)
    const { authStore, leadStore } = useContext(AppContext)
    const [filter, setFilter] = useState(leadStore.filter)

    useEffect(() => {
        setFilter(leadStore.filter)
    }, [leadStore.filter])

    // const phoneError = (() => {
    //     const digits = filter.phone.replace(/[^\d]/g, '')
    //     return digits.length !== 11 && digits.length !== 0
    // })()

    const canApplyFilter = 
        JSON.stringify({ ...filter, page: 0, limit: 0, sort: true }) !== 
        JSON.stringify({ ...leadStore.filter, page: 0, limit: 0, sort: true })

    const applyFilter = () => {
        leadStore.set_filter({
            ...filter,
            page: 1,
            limit: leadStore.filter.limit,
        })
    }

    const resetFilter = () => {
        leadStore.reset_filter()
        setFilter(leadStore.filter)
    }

    const toggleFilterVisibility = () => {
        setIsOpened(value => {
            localStorage.setItem('crm_filter_block_visible', !value)
            return !value
        })
    }

    const handleDateSelect = (propertyName) => {
        return (date) => setFilter(prev => ({ ...prev, [propertyName]: date }))
    }

    const handlePhoneInput = (phone) => {
        const digits = phone.replace(/[^\d]/g, '')
        if (digits.length > 15) {
            setFilter(prev => ({ ...prev, phone: digits.slice(0, 15) }))
            return
        }
        setFilter(prev => ({ ...prev, phone: digits }))
    }

    const isPhoneFilter = filter.phone?.length > 0
    const isEmployeeFilter = filter.employee?.length > 0
    const isStatusFilter = filter.status?.length > 0
    const isSourceFilter = filter.source?.length > 0
    const isDateFilter = filter.dateFrom || filter.dateTo

    const showEmployeeFilter = authStore.isSuperAdmin || authStore.isObserver

    return (
        <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title medium>Фильтр</Title>
                <Arrow 
                    onClick={toggleFilterVisibility}
                    isOpen={isOpened} />
            </div>
            {isOpened && <>
            <Form>
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Телефон</Label>
                        {isPhoneFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, phone: '' }) )}
                            />}
                    </div>
                    <Input
                        type='text'
                        value={filter.phone}
                        onChange={handlePhoneInput}
                        min={minDate} max={leadStore.filter.dateTo || maxDate}
                        small placeholder='Введите телефон'
                    />
                </Fieldset>
                {showEmployeeFilter &&
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Сотрудник</Label>
                        {isEmployeeFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, employee: [] }) )}
                            />}
                    </div>
                    <Select
                        value={filter.employee}
                        onChange={(empls) => setFilter(prev => ({ ...prev, employee: empls }))}
                        options={leadStore.employee_list}
                        placeholder='Введите ФИО'
                        small search multiple
                    />
                    
                    {filter.employee.length > 0 && 
                        <TagList>
                            {filter.employee.map((empl, idx) => {
                                return (
                                    <Tag
                                        onDelete={() => setFilter(prev => ({ ...prev, employee: prev.employee.filter((e) => e.id !== empl.id) }))}
                                        key={idx + Date.now()}
                                    >
                                        {empl.name}
                                    </Tag>
                                )
                            }
                            )}
                        </TagList>}
                </Fieldset>}
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Статус</Label>
                        {isStatusFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, status: [] }) )}
                            />}
                    </div>
                    <Select
                        value={filter.status}
                        onChange={(st) => setFilter(prev => ({ ...prev, status: st }))}
                        options={leadStore.status_list}
                        placeholder='Выберите статус'
                        small search multiple
                    />
                    {filter.status.length > 0 &&
                        <TagList>
                            {filter.status.map((st, idx) => {
                                return <Tag
                                    onDelete={() => setFilter(prev => ({ ...prev, status: prev.status.filter((s) => s.id !== st.id) }))}
                                    key={idx + Date.now()}
                                >
                                    {st.name}
                                </Tag>
                            }
                            )}
                        </TagList>}
                </Fieldset>
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Источник</Label>
                        {isSourceFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, source: [] }) )}
                            />}
                    </div>
                    <Select
                        value={filter.source}
                        onChange={(src) => setFilter(prev => ({ ...prev, source: src }))}
                        options={leadStore.source_list}
                        placeholder='Выберите источник'
                        small multiple
                    />
                    {filter.source.length > 0 &&
                        <TagList>
                            {filter.source.map((src, idx) => {
                                return <Tag
                                    onDelete={() => setFilter(prev => ({ ...prev, source: prev.source.filter((s) => s.id !== src.id) }))}
                                    key={idx + Date.now()}
                                >
                                    {src.name}
                                </Tag>
                            }
                            )}
                        </TagList>}
                </Fieldset>
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Дата</Label>
                        {isDateFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, dateFrom: '', dateTo: ''}) )}
                            />}
                    </div>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <Input
                            style={{ width: 132 }}
                            type='date'
                            value={filter.dateFrom}
                            onChange={handleDateSelect('dateFrom')}
                            min={minDate} max={leadStore.filter.dateTo || maxDate}
                            small placeholder='Начало'
                        />
                        <Input
                            style={{ width: 132 }}
                            type='date'
                            value={filter.dateTo}
                            onChange={handleDateSelect('dateTo')}
                            min={leadStore.filter.dateFrom || minDate} max={maxDate}
                            small placeholder='Конец'
                        />
                    </div>
                </Fieldset>
            </Form>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Button
                    onClick={applyFilter}
                    small
                    disabled={!canApplyFilter}
                >
                    Применить
                </Button>
                <Button
                    onClick={resetFilter}
                    small light
                >
                    Сбросить
                </Button>
            </div>
            </>}
        </Block>
    )
})