import style from './Block.module.css'
import { BlockCloseBtn } from './BlockCloseBtn'

export const Block = ({ children, extraLarge, large, close, colored, shadowed }) => {
    const blockClasses = style.block
        + (extraLarge ? ' ' + style.block_extra_large : '')
        + (large ? ' ' + style.block_large : '')
        + (colored ? ' ' + style.block_colored : '')
        + (shadowed ? ' ' + style.block_shadowed : '')

    return (
        <div className={blockClasses}>
            {close && <BlockCloseBtn onClose={close} />}
            {children}
        </div>
    )
}
