import style from './Centered.module.css'

export const Centered = ({ children }) => {
    return (
        <div className={style.centered}>
            <div>
                {children}
            </div>
        </div>
    )
}