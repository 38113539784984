import style from './Table.module.css'

export const RowControls = ({ edit, bitrix }) => {
    return (
        <div className={style.row_controls}>

            {edit &&
                <svg onClick={edit} className={style.edit} width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M 14.671875 4.457031 C 14.136719 4.519531 13.644531 4.730469 13.125 5.136719 C 12.984375 5.246094 10.964844 7.242188 8.644531 9.570312 C 4.546875 13.671875 4.410156 13.8125 4.246094 14.15625 C 4.011719 14.652344 3.183594 17.945312 3.148438 18.519531 C 3.113281 19.238281 3.300781 19.753906 3.773438 20.230469 C 4.242188 20.703125 4.761719 20.886719 5.507812 20.84375 C 6.113281 20.8125 9.296875 20.011719 9.824219 19.757812 C 10.191406 19.585938 10.234375 19.542969 14.453125 15.332031 C 16.796875 13 18.804688 10.960938 18.921875 10.804688 C 19.167969 10.492188 19.410156 9.984375 19.496094 9.632812 C 19.570312 9.332031 19.570312 8.664062 19.496094 8.367188 C 19.414062 8.046875 19.257812 7.691406 19.050781 7.375 C 18.839844 7.054688 17.207031 5.382812 16.804688 5.078125 C 16.164062 4.589844 15.4375 4.378906 14.671875 4.457031 Z M 14.515625 9.476562 C 15.324219 10.285156 15.984375 10.964844 15.984375 10.992188 C 15.984375 11.054688 9.097656 17.929688 8.972656 17.996094 C 8.820312 18.066406 5.507812 18.890625 5.351562 18.890625 C 5.183594 18.890625 5.109375 18.816406 5.109375 18.648438 C 5.109375 18.492188 5.933594 15.179688 6.003906 15.027344 C 6.070312 14.902344 12.945312 8.015625 13.007812 8.015625 C 13.03125 8.015625 13.714844 8.675781 14.515625 9.476562 Z M 14.515625 9.476562 ' />
                </svg>}

            {bitrix ?
                <a href={bitrix} style={{ lineHeight: 0 }} target='_blank' rel='noreferrer'>
                    <svg className={style.bitrix} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M8.97409 6.33224C5.49213 7.45772 5.14921 11.3002 5.413 13.0807L3.89623 13.8061L1.30235 15.9384L1.06055 20.4887L5.61083 24.3355H24.2736L28.7359 21.0822L27.7907 15.5867L23.9658 13.5643L22.9547 10.4868L20.0091 8.35459L16.4919 8.66234C15.4368 7.41669 12.456 5.20676 8.97409 6.33224Z' />
                        <path d='M20.1943 16.3762C20.1941 17.7776 19.6374 19.1215 18.6464 20.1124C17.6555 21.1033 16.3116 21.6601 14.9102 21.6603C13.8652 21.6603 12.8436 21.3504 11.9747 20.7697C11.1058 20.1891 10.4286 19.3639 10.0286 18.3984C9.62874 17.4329 9.52412 16.3705 9.72801 15.3455C9.93189 14.3205 10.4351 13.379 11.1741 12.6401C11.9131 11.9011 12.8545 11.3979 13.8795 11.194C14.9045 10.9901 15.9669 11.0947 16.9324 11.4946C17.8979 11.8945 18.7231 12.5718 19.3038 13.4407C19.8844 14.3096 20.1943 15.3312 20.1943 16.3762Z' fill='white' />
                        <path d='M14.4025 13.575V16.9195H17.3798M23.8212 13.463C25.2123 13.5701 26.5077 14.2118 27.4358 15.2535C28.364 16.2952 28.8525 17.6558 28.7991 19.0499C28.7457 20.4441 28.1542 21.7634 27.1489 22.7308C26.1436 23.6983 24.8026 24.2386 23.4074 24.2384L23.0795 24.2474H6.70271L6.62083 24.1652C5.58732 24.1651 4.57385 23.8801 3.69179 23.3414C2.80973 22.8028 2.09322 22.0315 1.62103 21.1121C1.14883 20.1928 0.939217 19.1611 1.01522 18.1304C1.09123 17.0997 1.44992 16.1099 2.05186 15.2698C2.85848 14.1442 4.05219 13.3559 5.40412 13.0562L5.412 13.0291C5.12858 11.6098 5.36512 10.136 6.07841 8.87665C6.79171 7.61735 7.93418 6.65664 9.29717 6.16995C10.6602 5.68327 12.1528 5.70308 13.5024 6.22578C14.8519 6.74848 15.9685 7.73918 16.6481 9.01697L16.7005 9.00195C17.45 8.58644 18.2969 8.37893 19.1536 8.40094C20.0102 8.42294 20.8454 8.67366 21.5725 9.12711C22.2996 9.58056 22.8923 10.2202 23.2889 10.9798C23.6856 11.7394 23.8719 12.5913 23.8286 13.4471L23.8212 13.463Z' stroke='#2FC7F7' strokeWidth='1.17238' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                </a>
                :
                <div style={{ width: 24, height: 24 }}></div>
            }

        </div>
    )
}
