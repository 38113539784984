import { useContext } from 'react'
import style from './ProfileData.module.css'
import { ProfileStatusDot } from './ProfileStatusDot'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'

export const ProfileIcon = observer(() => {
    const { authStore } = useContext(AppContext)

    return (
        <div className={style.profile_icon_container}>
            <svg className={style.profile_icon} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect width='40' height='40' rx='20' />
                <path d='M27.7274 28.4471C27.2716 27.1713 26.2672 26.0439 24.8701 25.2399C23.4729 24.4358 21.7611 24 20 24C18.2389 24 16.5271 24.4358 15.1299 25.2399C13.7328 26.0439 12.7284 27.1713 12.2726 28.4471' strokeWidth='2' strokeLinecap='round' />
                <circle cx='20' cy='16' r='4' strokeWidth='2' strokeLinecap='round' />
            </svg>
            <div className={style.profile_status_dots}>
                <ProfileStatusDot bool={authStore.isSuperAdmin} />
                <ProfileStatusDot bool={authStore.isAdminOfDay} />
                <ProfileStatusDot bool={authStore.isUserOfDay} />
                <ProfileStatusDot bool={authStore.isObserver} />
            </div>
        </div>
    )
})
