import { Block, Button, Fieldset, Select, Text, Title } from 'src/components'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'


export const AddUserOfDay = observer(({ onClose }) => {
    const { workdayStore, employeeStore } = useContext(AppContext)
    const [employee, setEmployee] = useState('')

    useEffect(() => {
        workdayStore.getAvailableEmployees()
    }, []) // eslint-disable-line

    const handleSubmit = async () => {
        await employeeStore.addUserOfDay(employee.id)
        if (!employeeStore.error.length) {
            onClose()
        }
    }

    const canSubmit = employee.id

    return (
        <Block large close={onClose}>
            <Title medium>Добавить сотрудника в сессию:</Title>
            {employeeStore.error &&
                <Text style={{ color: 'var(--orange)' }}>При отправке запроса произошла ошибка: {employeeStore.error}</Text>}
            <Fieldset>
                <Select
                    value={employee}
                    onChange={(empl) => setEmployee(empl)}
                    options={workdayStore.available_employees}
                    placeholder='Введите ФИО'
                    small search
                />
            </Fieldset>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    style={{ flex: 1 }} 
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                >
                    Добавить
                </Button>
                <Button
                    style={{ flex: 1 }}
                    light onClick={onClose}
                >
                    Отмена
                </Button>
            </div>
        </Block>
    )
})