import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { Arrow, Block, Button, CloseBtn, GridData, Label, Title } from 'src/components'

const isWorkdayVisibleByDefault = false

export const LeadsWorkday = observer(({ addUserOfDay, deleteUserOfDay }) => {
    const { employeeStore, authStore, appStore, leadStore, workdayStore } = useContext(AppContext)
    const isVisible = JSON.parse(localStorage.getItem('crm_workday_block_visible'))
    const [isOpened, setIsOpened] = useState(isVisible !== null ? isVisible : isWorkdayVisibleByDefault)

    const employeeActiveUpdate = (e) => {
        employeeStore.getEmployees()

        const data = JSON.parse(e.data)
        if (+data.id === +authStore.id || authStore.isSuperAdmin) {
            leadStore.set_filter({ ...leadStore.filter, page: 1 })
            authStore.getAuth()
        }
    }

    useEffect(() => {
        if (authStore.isAdminOfDay || authStore.isSuperAdmin || authStore.isUserOfDay) {
            employeeStore.getEmployees()
            appStore.ssEvents.addEventListener('employee_active_update', employeeActiveUpdate)
        }

        return () => {
            appStore.ssEvents.removeEventListener('employee_active_update', employeeActiveUpdate)
        }
    }, [workdayStore.is_workday_opened]) // eslint-disable-line

    const usersOfDay = employeeStore.employees?.filter((e) => e.user_of_day || e.admin_of_day)

    if (!workdayStore.is_workday_opened || !(authStore.isAdminOfDay || authStore.isSuperAdmin || authStore.isUserOfDay)) {
        return
    }

    return (
        <Block>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title medium>Сессия</Title>
                <Arrow 
                    onClick={_ => setIsOpened(value => {
                        localStorage.setItem('crm_workday_block_visible', !value)
                        return !value
                    })} 
                    isOpen={isOpened}
                />
            </div>

            {isOpened && <>

                {/* block only available for admins */}
                {(authStore.isAdminOfDay || authStore.isSuperAdmin || authStore.isUserOfDay) && <>
                    <GridData oneRow>
                    <Title small>Сотрудники дня</Title>
                    <Block colored>
                        <GridData oneRow>
                            {usersOfDay.length
                                ? usersOfDay.map((e) => {
                                    return (
                                        <div
                                            key={e.id + Date.now()}
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            <Label medium>
                                                {e.name + (e.admin_of_day ? ' (Админ дня)' : '')}
                                            </Label>
                                            {(authStore.isAdminOfDay || authStore.isSuperAdmin) &&
                                                <CloseBtn onClick={() => deleteUserOfDay(e)} />}
                                        </div>
                                    )
                                })
                                : <Label medium>Нет активных сотрудников</Label>
                            }
                        </GridData>
                    </Block>
                    </GridData>
                    {(authStore.isAdminOfDay || authStore.isSuperAdmin) &&
                        <GridData oneRow>
                            <Title small>Добавление сотрудника дня</Title>
                            <Button
                                add
                                small
                                onClick={addUserOfDay}
                            >
                                Добавить
                            </Button>
                        </GridData>}
                </>}

                {/* block only available for employee of day or admin */}
                {(authStore.isUserOfDay || authStore.isAdminOfDay) &&
                    <GridData oneRow>
                        <Title small>Завершение рабочего дня</Title>
                        <Button
                            small
                            light
                            onClick={() => deleteUserOfDay({ id: authStore.id, name: authStore.name })}
                        >
                            Завершить свой день
                        </Button>
                    </GridData>}
            </>}
        </Block>
    )

})
