import { getAuth } from './auth.api'
import { getLogin } from './auth.api'
import { getLogout } from './auth.api'

import { getEmployeeList } from './leads.api'
// import { getStatusList } from './leads.api'
import { getSourceList } from './leads.api'
import { getLeads } from './leads.api'
import { setStatus } from './leads.api'
import { getRepeats } from './leads.api'

import { getAvailableEmployees } from './workday.api'
import { getWorkday } from './workday.api'
import { setWorkday } from './workday.api'
import { closeWorkday } from './workday.api'

import { getEmployees } from './employee.api'
import { adminGetEmployees } from './employee.api'
import { createEmployee } from './employee.api'
import { editEmployee } from './employee.api'
import { deleteEmployee } from './employee.api'
import { addUserOfDay } from './employee.api'
import { deleteUserOfDay } from './employee.api'
import { setEmployeeStatus } from './employee.api'

import { getAnalytics } from './analytics.api'

export const authApi = {
    getAuth,
    getLogin,
    getLogout,
}

export const leadApi = {
    getEmployeeList,
    // getStatusList,
    getSourceList,
    getLeads,
    setStatus,
    getRepeats,
}

export const workdayApi = {
    getAvailableEmployees,
    getWorkday,
    setWorkday,
    closeWorkday,
}

export const employeeApi = {
    getEmployees,
    adminGetEmployees,
    createEmployee,
    editEmployee,
    deleteEmployee,
    addUserOfDay,
    deleteUserOfDay,
    setEmployeeStatus,
}

export const analyticsApi = {
    getAnalytics,
}