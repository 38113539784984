import { itemColor } from 'src/assets/data'
// import { ItemCopyBtn } from './ItemCopyBtn'
import style from './Table.module.css'
import { SortBtn } from './SortBtn'
import { ItemCopyBtn } from './ItemCopyBtn'
import { handleCopy, handleWhatsapp } from 'src/assets/utils'
import { WhatsappBtn } from './WhatsappBtn'

export const TableItem = ({ children, copyable, sortable, sorted, large, colored, whatsapp }) => {
    const itemClasses = style.table_item
        + (large ? ' ' + style.table_item_large : '')
        + (colored ? '  ' + style[
            Object.entries(itemColor).reduce((s, p) => {
                return p[1].indexOf(children) !== -1 ? p[0] : s
            }, '')
        ] : '')

    const isCopyable = copyable && children !== '-'

    return (
        <div className={itemClasses}>
            {sortable && <SortBtn sorted={sorted} onClick={sortable} />}
            {isCopyable && <ItemCopyBtn onClick={handleCopy(children)} />}
            {whatsapp && <WhatsappBtn whatsapp={handleWhatsapp(children)} />}
            <p>{children}</p>
        </div>
    )
}
