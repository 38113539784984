import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { AppContext } from 'src'
import { Block, Button, Fieldset, Form, Label, Select, Title } from 'src/components'
import { toJS } from 'mobx'

const EXPORT_FORMATS = [
    { id: 1, name: '.xlsx'}
]

export const ExportAnalytics = observer(({ onClose }) => {
    const { analyticsStore } = useContext(AppContext)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (fileName) => {
        const datesBetween = Object.keys(toJS(analyticsStore.leads[analyticsStore.clubs_list[0].name]))
        const dates = `От: ${datesBetween[0]} До: ${datesBetween[datesBetween.length - 1]}`

        const clubList = analyticsStore.filter.clubs.length
            ? analyticsStore.filter.clubs
            : analyticsStore.clubs_list
        
        const dataToAdd = clubList.map(currentClub => {
            const newRow = {
                [dates]: currentClub.name,
                ...analyticsStore.leads[currentClub.name]
            }

            return newRow
        })

        const ws = XLSX.utils.json_to_sheet(dataToAdd)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    const handleExportSubmit = () => {
        exportToCSV(`leads_${new Date().toLocaleString()}`)
        onClose()
    }

    const canExport = analyticsStore.leads

    return (
        <Block large close={onClose}>
            <Title medium>Экспорт обращений</Title>
            <Form>
                <Fieldset>
                    <Label>Формат</Label>
                    <Select options={EXPORT_FORMATS} value={EXPORT_FORMATS[0]} onChange={() => {}} />
                </Fieldset>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    style={{ flex: 1 }}
                    onClick={handleExportSubmit}
                    disabled={!canExport} 
                >
                    Экспорт
                </Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
})