import style from './Tag.module.css'
import { TagButton } from './TagButton'

export const Tag = ({ children, opened, duplicate, closed, onDelete }) => {
    const tagClasses = style.tag
        + (opened ? ' ' + style.tag_opened : '')
        + (duplicate ? ' ' + style.tag_duplicate : '')
        + (closed ? ' ' + style.tag_closed : '')

    return (
        <div className={tagClasses}>
            <p>{children}</p>
            <TagButton onDelete={onDelete} />
        </div>
    )
}
