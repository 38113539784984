import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Arrow, Block, Button, Fieldset, Form, InfoTable, Input, Label, Select, Table, Text, Title } from 'src/components'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { COPY_DATA_LEADS, SHOW_DATA_LEAD_EDIT } from 'src/assets/data'
import { SHOW_DATA_LEAD_REPEATS } from 'src/assets/data'

export const EditLead = observer(({ onClose }) => {
    const { leadStore } = useContext(AppContext)
    const [showInfo, setShowInfo] = useState(true)
    const [showRepeats, setShowRepeats] = useState(false)
    const [showNote, setShowNote] = useState(true)
    
    useEffect(() => {
        leadStore.getRepeats()
    }, []) // eslint-disable-line

    const repeatQuantity = leadStore.repeats?.length || 'нет'
    
    const toggleNote = () => setShowNote(prev => !prev)
    const toggleRepeats = () => setShowRepeats(prev => !prev)
    const toggleInfo = () => setShowInfo(prev => !prev)

    const notes = leadStore.lead.note ? Object.entries(JSON.parse(leadStore.lead.note)) : null
    const isNotes = notes?.length > 0

    const isRepeats = leadStore.repeats?.length > 0

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: leadStore.status_list.filter(s => s.id === +leadStore.lead.status)[0],
            link: leadStore.lead.link || ''
        },
        validate: values => {
            const errors = {}
            if (values.status.id === 5 && !values.link) {
                errors.link = 'This field is required'
                return errors
            }
            const link = values.link.trim()
            // const linkLengthError = link.length < 12
            const pattern = /^(http:\/\/|https:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5})(:[0-9]{1,5})?(\/.*)?$/i
            // const linkStartPathError = !(link.startsWith('http://') || link.startsWith('https://'))
            const linkStartPathError = !pattern.test(link)
            if (values.status.id === 5 && linkStartPathError) {
                errors.link = 'Invalid link'
                return errors
            }
        },
        validationSchema: Yup.object().shape({
            status: Yup.mixed().test('status', 'status', (s) => s?.name),
        }),
        onSubmit: async (values, actions) => {
            const link = !(values.link.startsWith('https://') || values.link.startsWith('http://')) 
                ? `https://${values.link}`
                : values.link

            const data = {
                id: leadStore.lead.id,
                status: values.status.id,
                status_name: values.status.name,
                link: values.status.id === 5 ? link : leadStore.lead.link
            }
            await leadStore.set_lead_status(data)
            if (!leadStore.error) {
                actions.resetForm()
                onClose()
            }
        },
    })

    const canSubmit =
        (+leadStore.lead.status !== formik.values.status?.id ||
        leadStore.lead.link !== formik.values.link) &&
        !formik.errors.link

    return (
        <Block extraLarge close={onClose}>
            <Title medium>Редактировать заявку</Title>
            {leadStore.error &&
                <Text style={{ color: 'var(--orange)' }}>При отправке запроса произошла ошибка: {leadStore.error}</Text>}
                <Block colored extraLarge>
                    <div className='flex-sb-c'>
                        <Title small>Основная информация</Title>
                        <Arrow onClick={toggleInfo} isOpen={showInfo} />
                    </div>
                {showInfo && 
                    <InfoTable
                        data={leadStore.lead}
                        showData={SHOW_DATA_LEAD_EDIT}
                        copyData={COPY_DATA_LEADS}
                    />
                
                }
            </Block>
            
            {isNotes && 
                <Block colored extraLarge>
                    <div className='flex-sb-c'>
                        <Title small>Комментарии</Title>
                        <Arrow onClick={toggleNote} isOpen={showNote} />
                    </div>

                    {showNote && 

                        <InfoTable
                            data={notes}
                            showData={notes}
                            copyData={[]}
                            />
                        
                    }
                </Block>}

            {isRepeats && 
                <Block colored extraLarge>
                    <div className='flex-sb-c'>
                        <Title small>Дубли ({repeatQuantity})</Title>
                        <Arrow onClick={toggleRepeats} isOpen={showRepeats} />
                    </div>

                    {showRepeats && 
                        <Table
                            data={leadStore.repeats}
                            showData={SHOW_DATA_LEAD_REPEATS}
                        />
                    }
                </Block>}

            <Form onSubmit={formik.handleSubmit} id='edit-lead-form'>
                <Fieldset>
                    <Label>Статус</Label>
                    <Select
                        options={leadStore.status_list}
                        error={formik.errors.status && formik.touched.status}
                        name='status' id='status'
                        onChange={(v) => formik.setFieldValue('status', v)}
                        value={formik.values.status} //disabled={employeeStore.loading}
                    />
                </Fieldset>
                {formik.values.status.id === 5 && <Fieldset>
                    <Label>Ссылка b24</Label>
                    <Input
                        formik
                        placeholder='Вставьте ссылку на B24'
                        error={formik.errors.link && formik.touched.link}
                        type='text' name='link' id='link'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.link} //disabled={employeeStore.loading}
                    />
                </Fieldset>}
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    style={{ flex: 1 }}
                    form='edit-lead-form'
                    type='submit'
                    disabled={Object.keys(formik.errors).length || !canSubmit} //|| employeeStore.loading}
                >
                    Сохранить
                </Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
})