import style from './Limits.module.css'

export const LimitItem = ({ selected, placeholder, children, ...props }) => {
    const itemClasses = style.limits_item
        + (selected ? ' ' + style.limits_item_selected : '')
        + (placeholder ? ' ' + style.limits_item_placeholder : '')

    return (
        <div {...props} className={itemClasses}>
            {children}
        </div>
    )
}
