import React, { useEffect, useRef, useState } from 'react'
import style from './Select.module.css'
import { SelectArrow } from './SelectArrow';
import { SelectList } from './SelectList';

export const Select = ({ formik, placeholder, small, options = [], value, onChange, onBlur, error, search, multiple }) => {
    const [isOpened, setIsOpened] = useState(false)
    const rootEl = useRef(null)
    const inputEl = useRef(null)

    // only for search option
    const [searchStr, setSearchStr] = useState('')
    const filteredOptions = (search || multiple) ? 
        options.filter(option =>
            option.name.toLowerCase().includes(searchStr.toLowerCase().trim()) 
            && (multiple ? !value.filter(val => val.id === option.id).length : true)
        ) : options

    const selectClasses = style.select
        + (isOpened ? ' ' + style.select_opened : '')
        + (small ? ' ' + style.select_small : '')
        + (error ? ' ' + style.select_error : '')

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setIsOpened(false)
        if (isOpened) document.addEventListener('click', onClick)
        return () => { isOpened && document.removeEventListener('click', onClick) }
    }, [isOpened]);

    const handleSelect = (item) => {
        if (multiple) {
            // onChange(prev => [...prev, item])
            onChange([...value, item])
        }
        if (!multiple) {
            // onChange(item)
            onChange(item)
            
        }
        if (search) {
            setSearchStr('')
        }
        // onChange({ target: { value: item, name: props.name, id: props.id } })
        toggleSelect()
    }

    const toggleSelect = () => {
        if (isOpened) inputEl.current.blur()
        setIsOpened(prev => !prev)
    }

    return (
        <div className={selectClasses} ref={rootEl}>
            <input
                type='text'
                ref={inputEl}
                value={!search ? (value ? value.name : '') : isOpened ? searchStr : (value.name || '')}
                onClick={toggleSelect}
                onChange={e => setSearchStr(e.target.value)}
                readOnly={!search}
                placeholder={placeholder}
                onBlur={onBlur}
            />

            <SelectArrow isOpened={isOpened} />

            {isOpened &&
                <div className={style.select_container}>
                    <SelectList options={filteredOptions} value={value} handleSelect={handleSelect} />
                </div>
            }
        </div>
    )
}