import { makeAutoObservable } from 'mobx'
import { workdayApi } from 'src/http'

class WorkdayStore {

    skip_workday_open = false
    is_workday_opened = null
    should_workday_open = null

    // session data
    available_employees = []
    admin_of_day = null
	
    // common
	loading = false
    error = ''
    
    
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }


    ////////////////////////////////////////////////////////////////// 
    // GET AVAILABLE EMPLOYEES
    //////////////////////////////////////////////////////////////////

    *getAvailableEmployees() {
        this.loading = true
        const response = yield workdayApi.getAvailableEmployees()

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.available_employees = response.data.employees.map(e => ({ ...e, selected: false }))
        this.handleSuccess()
    }

    ////////////////////////////////////////////////////////////////// 
    // GET SESSION (available for user_of_day or admin_of_day)
    //////////////////////////////////////////////////////////////////
    
    *getWorkday() {
        this.loading = true
        const response = yield workdayApi.getWorkday()

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.is_workday_opened = response.data.is_workday_opened
        this.should_workday_open = response.data.should_workday_open
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // SET SESSION (available for admin_of_day)
    //////////////////////////////////////////////////////////////////

    *setWorkday() {
        this.loading = true
        const response = yield workdayApi.setWorkday(this.available_employees.filter(e => e.selected).map(e => +e.id))

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        // this.selected_employees = response.data
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // CLOSE SESSION (available for user_of_day)
    //////////////////////////////////////////////////////////////////

    *closeWorkday(id) {
        this.loading = true
        const response = yield workdayApi.closeWorkday(id)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        // this.selected_employees = this.selected_employees.filter((empl) => empl.id !== id)
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // SET SELECTED EMPLOYEES (for workday creation)
    //////////////////////////////////////////////////////////////////

    setSelected(empl) {
        this.available_employees = this.available_employees.map((emp) => {
            if (empl.id === emp.id) {
                return { ...emp, selected: !emp.selected}
            }
            return { ...emp }
        })
    }


    skipWorkdayOpen() {
        this.skip_workday_open = true
    }


    ////////////////////////////////////////////////////////////////// 
    // COMMON ERROR HANDLER (sets error message and loading = false)
    //////////////////////////////////////////////////////////////////

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default WorkdayStore