import { makeAutoObservable } from 'mobx'
import { employeeApi } from 'src/http'

const INIT_FILTER = {
    all: true,
    limit: 10,
    page: 1,
    userOfDay: null,
    status: null,
}

const SAVED_FILTER = JSON.parse(localStorage.getItem('crm_filter_employees'))

class EmployeeStore {

    // employee data
    
    // active employees
	employees = []

    // admin employees page
    employeesAdmin = []
    pages = null
    filter = SAVED_FILTER || INIT_FILTER
    limits = [10, 25, 50]

    // single emloyee (for editing)
    employeeAdmin = null
	
    // common
	loading = false
    error = ''
    
    
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }


    ////////////////////////////////////////////////////////////////// 
    // GET EMPLOYEES
    //////////////////////////////////////////////////////////////////
    
    *getEmployees() {
        this.loading = true
        const response = yield employeeApi.getEmployees(this.filter)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.employees = response.data.employees
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // ADMIN GET EMPLOYEES ( only for super admins) => (gets params list)
    //////////////////////////////////////////////////////////////////
    
    *adminGetEmployees() {
        this.loading = true
        const response = yield employeeApi.adminGetEmployees(this.filter)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.new_employee_counter = 0
        this.employeesAdmin = response.data.employees.map(e => ({ ...e, status_name: +e.status === 0 ? 'Да' : 'Нет' }))
        this.pages = response.data.pages
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // CREATE EMPLOYEE (available only for super admins) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *createEmployee(empl) {
        this.loading = true
        const response = yield employeeApi.createEmployee(empl)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.employees = [{ ...empl, id: response.data.id }, ...this.employees]
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // EDIT EMPLOYEE (available only for super admins) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *editEmployee(empl) {
        this.loading = true
        const response = yield employeeApi.editEmployee(empl)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        // this.employees = this.employees.map((employee) => {
        //     if (employee.id === empl.id) {
        //         return { ...employee, ...empl }
        //     }
        //     return { ...employee }
        // })
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // DELETE EMPLOYEE (available only for super admins) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *deleteEmployee(id) {
        this.loading = true
        const response = yield // employeeApi.deleteEmployee(id)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }
        
        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.employees = this.employees.filter((employee) => employee.id !== id)
        this.handleSuccess()
    }

    

    ////////////////////////////////////////////////////////////////// 
    // ADD user_of_day
    //////////////////////////////////////////////////////////////////

    *addUserOfDay(id) {
        this.loading = true
        const response = yield employeeApi.addUserOfDay(id)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }
        
        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        // this.employees = this.employees.filter((employee) => employee.id !== id)
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // DELETE user_of_day
    //////////////////////////////////////////////////////////////////

    *deleteUserOfDay(id) {
        this.loading = true
        const response = yield employeeApi.deleteUserOfDay(id)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }
        
        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        // this.employees = this.employees.filter((employee) => employee.id !== id)
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // SET EMPLOYEE STATUS
    //////////////////////////////////////////////////////////////////

    *setEmployeeStatus(data) {
        this.loading = true
        const response = yield employeeApi.setEmployeeStatus(data)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }
        
        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.handleSuccess()
    }

    
    ////////////////////////////////////////////////////////////////// 
    // SET ONE EMPLOYEE
    //////////////////////////////////////////////////////////////////

    selectEmployeeAdmin(id) {
        this.employeeAdmin = this.employeesAdmin.find(e => e.id === id)
    }


    ////////////////////////////////////////////////////////////////// 
    // UPDATE EMPLOYEE (FOR SSE)
    //////////////////////////////////////////////////////////////////

    updateEmployee(data) {
        this.employeesAdmin = this.employeesAdmin.map(e => {
            if (e.id === data.id) {
                return { ...e,  ...data, status_name: +(data.status !== undefined ? data.status : e.status) === 0 ? 'Да' : 'Нет' }
            }
            return { ...e }
        })

        if (data.id === this.employeeAdmin?.id) {
            this.employeeAdmin = { ...this.employeeAdmin, ...data, status_name: +(data.status !== undefined ? data.status : this.employeeAdmin.status) === 0 ? 'Да' : 'Нет' }
        }
    }


    ////////////////////////////////////////////////////////////////// 
    // SET PAGE
    //////////////////////////////////////////////////////////////////

    set_page(page) {
        this.filter = { ...this.filter, page: (page > this.pages) ? 1 : page }
    }

    set_limit(limit) {
        this.filter = { ...this.filter, limit: (this.limits.indexOf(limit) < 0) ? this.limits[0] : limit, page: 1 }
        this.saveFilter()
    }


    saveFilter() {
        localStorage.setItem('crm_filter_employees', JSON.stringify({ ...this.filter, page: 1 }))
    }


    ////////////////////////////////////////////////////////////////// 
    // COMMON ERROR HANDLER (sets error message and loading = false)
    //////////////////////////////////////////////////////////////////

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default EmployeeStore