import style from './CloseBtn.module.css'

export const CloseBtn = ({ small, ...props }) => {
    const buttonClasses = style.close_btn
        + (small ? ' ' + style.close_btn_sm : '')

    return (
        <button { ...props } className={buttonClasses}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M18 6L6 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M6 6L18 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
        </button>
    )
}
