import { animationOffset } from './animation'
import style from './Limits.module.css'
import { LimitItem } from './LimitItem'

export const Limits = ({ setLimit, limit, limits }) => {
    const index = limits.indexOf(limit)
    const offset = animationOffset(limits.length, index + 1)
    const limitStyles = style.limits + ' ' + style[`limits_${offset}`]

    const renderedLimitList = limits.map((l, idx) => {
        return (
            <LimitItem
                key={idx + Date.now()}
                selected={l === limit}
                placeholder={!l}
                onClick={l ? () => setLimit(l) : null}
            >
                {l}
            </LimitItem>
        )
    })

    return (
        <div className={limitStyles}>
            {renderedLimitList}
        </div>
    )
}
