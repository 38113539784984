import { authApi } from './config'


export const getAvailableEmployees = async () => {
    try {
        const response = await authApi.get('/employee?status=0&userOfDay=false')
        return response
    } catch (err) {
        return err.response
    }
}

export const getWorkday = async () => {
    try {
        const response = await authApi.get('/workday')
        return response
    } catch (err) {
        return err.response
    }

}

export const setWorkday = async (employees) => {
    try {
        const response = await authApi.post('/workday', { employees })
        return response
    } catch (err) {
        return err.response
    }
    
}

export const closeWorkday = async (id) => {
    try {
        // delete-line
        const response = await authApi.delete('/workday')
        return response
    } catch (err) {
        return err.response.data
    }
}