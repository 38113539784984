import style from './Title.module.css'

export const Title = ({ medium, small, children, ...props }) => {
    const titleClasses = style.title
        + (medium ? ' ' + style.title_medium : '')
        + (small ? ' ' + style.title_small : '')

    if (medium) {
        return <h2 {...props} className={titleClasses}>{children}</h2>
    }
    
    if (small) {
        return <h3 {...props} className={titleClasses}>{children}</h3>
    }

    return <h1 {...props} className={titleClasses}>{children}</h1>
}