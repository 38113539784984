import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from 'src'
import { Block, Button, Centered, Container, List, Logo, Text, Title } from 'src/components'


export const CheckEmpls = observer(() => {
    const { workdayStore, authStore } = useContext(AppContext)
    const navigate = useNavigate()

    const selectedEmployees = workdayStore.available_employees.filter(e => e.selected)
    const myself = workdayStore.available_employees.find(e => e.id === authStore.id)

    const handleSubmit = async () => {
        await workdayStore.setWorkday()
        if (!workdayStore.error.length) {
            authStore.getAuth()
        }
    }

    return (
        <Container>
            <Centered>
                <Block large>
                    <Logo small />
                    {workdayStore.error &&
                        <Text style={{color: 'var(--orange)'}}>При отправке запроса произошла ошибка: {workdayStore.error}</Text>}
                    <div>
                        <Button
                            onClick={() => navigate('/workday/select_employees')}
                            back
                        >
                            Вернуться назад
                        </Button>
                    </div>
                    <Title medium>Вы выбрали сотрудников:</Title>
                    <List>
                        {myself &&
                            <Text key={authStore.id + Date.now()}>{authStore.name + ' (я)'}</Text>}
                        {selectedEmployees.map((empl) => <Text key={empl.id + Date.now()}>{empl.name}</Text>)}
                    </List>
                    <Button
                        disabled={workdayStore.loading || !selectedEmployees.length}
                        onClick={handleSubmit}
                    >
                        Продолжить
                    </Button>
                </Block>
            </Centered>
        </Container>
    )
})