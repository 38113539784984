import { makeQueryParamsStr } from 'src/assets/utils'
import { authApi } from './config'

export const getEmployees = async () => {
    try {
        const response = await authApi.get('/employee')
        return response
    } catch (err) {
        return err.response
    }
}

export const adminGetEmployees = async (filter) => {
    try {
        const response = await authApi.get('/employee/admin?' + makeQueryParamsStr(filter))
        return response
    } catch (err) {
        return err.response
    }
}

export const createEmployee = async (employee) => {
    try {
        const response = await authApi.post('/employee', employee)
        return response
    } catch (err) {
        return err.response
    }
    
}

export const editEmployee = async (employee) => {
    try {
        const response = await authApi.put('/employee/' + employee.id, employee)
        return response
    } catch (err) {
        return err.response
    }
}

export const deleteEmployee = async (id) => {
    try {
        const response = await authApi.delete('/employee' + id)
        return response
    } catch (err) {
        return err.response
    }
}

export const addUserOfDay = async (id) => {
    try {
        const response = await authApi.post('employee/user_of_day/' + id)
        return response
    } catch (err) {
        return err.response
    }
}

export const deleteUserOfDay = async (id) => {
    try {
        const response = await authApi.delete('employee/user_of_day/' + id)
        return response
    } catch (err) {
        return err.response
    }
}

export const setEmployeeStatus = async (data) => {
    try {
        const response = await authApi.post('employee/status/' + data.id, data)
        return response
    } catch (err) {
        return err.response
    }
}
