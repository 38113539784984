import style from './Arrow.module.css'

export const Arrow = ({ isOpen, ...props }) => {
    const arrowClasses = style.arrow
        + (isOpen ? ' ' + style.arrow_selected : '')
    return (
        <svg {...props} className={arrowClasses} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6 9L12 15L18 9' strokeWidth='2' strokeLinecap='round' />
        </svg>
    )
}
