import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { AppContext } from 'src'
import { Block, Button, GridData, Title } from 'src/components'
import { AddEmployee } from 'src/modals'

export const EmoloyeeControls = observer(({ closeWorkday }) => {
    const { modalStore, workdayStore } = useContext(AppContext)

    const addEmployeeModal = () => modalStore.setModal(
		<AddEmployee onClose={() => modalStore.setModal(null)}/>)

    return (
        <Block>
            <GridData oneRow>
                <Title small>Добавить нового сотрудника</Title>
                <Button onClick={addEmployeeModal} add small>Добавить</Button>
            </GridData>

            <GridData oneRow>
                <Title small>Завершение рабочего дня (для всех)</Title>
                <Button
                    onClick={closeWorkday}
                    small
                    light
                    disabled={!workdayStore.is_workday_opened}
                >
                    Завершить
                </Button>
            </GridData>
        </Block>
    )
})