import { useContext, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'

import { Block, Button, Fieldset, Form, Label, Select, Text, Title } from 'src/components'
import { PAGINATION_OPTIONS, YES_NO_OPTIONS } from 'src/assets/data'

export const AppSettings = observer(({ onClose }) => {
    const { employeeStore, appStore, authStore } = useContext(AppContext)
    const [settingsCopy, setSettingsCopy] = useState(appStore.settings)

    const handleSettingSelect = (option) => {
        return (value) => {
            setSettingsCopy(previousState => ({ ...previousState, [option]: value }))
        } 
    }

    const handleSettingSubmit = () => {
        appStore.setSettings(settingsCopy)
        onClose()
    }

    const canSubmitSettings = JSON.stringify(settingsCopy) !== JSON.stringify(appStore.settings)

    return (
        <Block large close={onClose}>
            <Title medium>Настройки</Title>
            <Form>
                {employeeStore.error &&
                    <Text style={{color: 'var(--orange)'}}>При отправке запроса произошла ошибка: {employeeStore.error}</Text>}

                <Fieldset>
                    <Label>Отображение управления постраничного перехода</Label>
                    <Select
                        options={PAGINATION_OPTIONS}
                        onChange={handleSettingSelect('pagination_view')}
                        value={settingsCopy.pagination_view}
                    />
                </Fieldset>

                {authStore.isSuperAdmin &&
                    <Fieldset>
                        <Label>Пропускать выбор сотрудников</Label>
                        <Select
                            options={YES_NO_OPTIONS}
                            onChange={handleSettingSelect('skip_workday_open')}
                            value={settingsCopy.skip_workday_open}
                        />
                    </Fieldset>}
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    style={{ flex: 1 }}
                    onClick={handleSettingSubmit}
                    disabled={!canSubmitSettings}
                >
                    Сохранить
                </Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
})