import style from './Checkbox.module.css'

export const Checkbox = ({ children, disabled, checked = false, onClick }) => {
    const checkboxClasses = style.checkbox
        + (disabled ? ' ' + style.checkbox_disabled : '')

    if (disabled) {
        onClick = () => {}
    }

    return (
        <div className={checkboxClasses}>
            <input />
            <svg onClick={onClick} type='checkbox' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect className={style.animate_svg} fill={checked ? 'var(--orange)' : '#fff'} x='0.5' y='0.5' width='19' height='19' rx='5.5' />
                <rect  stroke={checked ? 'var(--orange)' : 'var(--grey-01)'} x='0.5' y='0.5' width='19' height='19' rx='5.5'/>
                <path className={style.animate_svg} strokeDashoffset={checked ? '0' : '9'} strokeDasharray='9' d='M7 9.6L9.25 12L13 8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <label style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginRight: 10 }}>{children}</label>
        </div>
    )
}
