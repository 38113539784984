import { makeAutoObservable } from 'mobx'

class ModalStore {

    modal = null
    
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

    setModal(modal) {
        this.modal = modal
    }
    
}

export default ModalStore