import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./layout/Header/Header";
import { CheckEmpls } from "./pages/CheckEmpls/CheckEmpls";
import { Leads } from "./pages/Leads/Leads";
import { Login } from "./pages/Login/Login";
import { SelectEmpls } from "./pages/SelectEmpls/SelectEmpls";
import { Employees } from "./pages/Employees/Employees";
import { useContext, useEffect } from "react";
import { AppContext } from "src";
import { observer } from "mobx-react-lite";
import { Loader, Overlay } from "src/components";
import { Analytics } from "./pages/Analytics/Analytics";


export const App = observer(() => {
	const { appStore, authStore, workdayStore, modalStore, leadStore, employeeStore, analyticsStore } = useContext(AppContext)

	const sseWorkdayStatus = (e) => {
		workdayStore.getWorkday()
		authStore.getAuth()
	}

	useEffect(() => {

		// firsh touch
		if (authStore.isAuth === null) {
			authStore.getAuth()
		}

		// authorized => getting workday data
		if (authStore.isAuth && workdayStore.is_workday_opened === null) {
            authStore.getAuth()
			workdayStore.getWorkday()
		}

		// authorized and workday is not opened => getting available employees
		if (authStore.isAuth && workdayStore.is_workday_opened === false && workdayStore.should_workday_open) {
			workdayStore.getAvailableEmployees()
		}

	}, [authStore.isAuth, workdayStore.is_workday_opened, workdayStore.should_workday_open]) // eslint-disable-line

	// sse handler
	useEffect(() => {
		if (authStore.isAuth) {
			appStore.connectSSE()
			appStore.ssEvents.addEventListener('workday_status', sseWorkdayStatus)
		}
		return () => {
			if (appStore.ssEvents) {
				appStore.ssEvents.removeEventListener('workday_status', sseWorkdayStatus)
				appStore.disconnectSSE()
			}
		}
	}, [authStore.isAuth]) // eslint-disable-line

	// escape btn handler
	useEffect(() => {
		const handleEsc = (e) => {
			if (e.key === 'Escape' && modalStore.modal) {
				modalStore.setModal(null)
			}
		}
		window.addEventListener('keydown', handleEsc)
		return () => {
			window.removeEventListener('keydown', handleEsc)
		}
	}, []) // eslint-disable-line

	
	const canCheckSelected = workdayStore.available_employees.filter(s => s.selected).length
	
	const skipWorkdayOpen = 
		((workdayStore.skip_workday_open === true || appStore.settings.skip_workday_open.value === true) &&
		authStore.isSuperAdmin) || authStore.isObserver

	const shouldSelectEmpls = 
		workdayStore.is_workday_opened === false &&
		workdayStore.should_workday_open === true &&
		!skipWorkdayOpen
	
	const showHeader = authStore.isAuth && !shouldSelectEmpls


	const isLoading = authStore.loading || leadStore.loading || employeeStore.loading || workdayStore.loading || analyticsStore.loading

	return (
		authStore.isAuth === null || (authStore.isAuth && workdayStore.is_workday_opened === null) ? <div></div> :
			<div className='app'>
				{isLoading && <Loader />}
				{showHeader && <Header />}

				{modalStore.modal &&
					<Overlay>
						{modalStore.modal}
					</Overlay>
				}

				<Routes>
					<Route path='/' element={
						authStore.isAuth ?
							!shouldSelectEmpls ?
								<Navigate to='/leads' /> :
								<Navigate to='/workday' /> :
							<Login />
					} />

					{authStore.isAuth &&

						(!shouldSelectEmpls ?

							// when dont have to create workday
							<Route path='/' element={<Outlet />} >
								<Route path='/leads' element={<Leads />} />
								<Route path='/employees' element={<Employees />} />
								<Route path='/analytics' element={<Analytics />} />
								<Route path="*" element={<Navigate to="/leads" />} />
							</Route> :

							// when have to create workday
							<Route path='/workday' element={<Outlet />} >
								<Route path='/workday' element={<Navigate to="/workday/select_employees" />} />
								<Route path='/workday/select_employees' element={<SelectEmpls />} />
								<Route
									path='/workday/check_selected_employees'
									element={canCheckSelected ? <CheckEmpls /> : <Navigate to="/workday/select_employees" />} />
								<Route path="*" element={<Navigate to="/workday/select_employees" />} />
							</Route>
						)
					}

					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</div>
	);
})
