import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { Arrow, Block, Button, Checkbox, Fieldset, Form, GridData, Label, Select, Text, Title } from 'src/components'
import { NAMED_DATA_PROPERTIES } from 'src/assets/data'

const EXPORT_FORMATS = [
    { id: 1, name: '.xlsx'}
]

export const ExportLeads = observer(({ onClose }) => {
    const { leadStore } = useContext(AppContext)
    const [showSettings, setShowSettings] = useState(false)

    const isDataItemChecked = (propertyName) => leadStore.dataToExport.find(dataItem => dataItem === propertyName)

    const handleDataItemToggle = (propertyName) => {
        return () => 
            isDataItemChecked(propertyName)
                ? leadStore.setDataToExport(leadStore.dataToExport.filter(dataItem => dataItem !== propertyName))
                : leadStore.setDataToExport([...leadStore.dataToExport, propertyName])
    }

    const toggleSettings = () => setShowSettings(prev => !prev)

    const columns = leadStore?.leadsAll[0] ? Object.keys(leadStore?.leadsAll[0]) : []

    useEffect(() => {
        leadStore.getLeadsAll()
    }, []) // eslint-disable-line

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (rawData, fileName) => {
        const dataToAdd = rawData.map(row => {
            return leadStore.dataToExport.reduce((newRow, dataItem) => {
                if (NAMED_DATA_PROPERTIES[dataItem]) {
                    newRow[NAMED_DATA_PROPERTIES[dataItem]] = row[dataItem]
                } else {
                    newRow[dataItem] = row[dataItem]
                }
                return newRow
            }, {})
        })
        const ws = XLSX.utils.json_to_sheet(dataToAdd)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    const handleExportSubmit = () => {
        exportToCSV(leadStore.leadsAll, `leads_${new Date().toLocaleString()}`)
        onClose()
    }

    const canExport = leadStore.dataToExport?.length > 0

    return (
        <Block large close={onClose}>
            <Title medium>Экспорт лидов</Title>
            <Block colored>
                <GridData>
                    <Title small>Количество</Title>
                    <Text>{leadStore.leadsAll.length}</Text>
                </GridData>
            </Block>
            <Block colored>
                <div className='flex-sb-c'>
                    <Title small>Аттрибуты</Title>
                    <Arrow onClick={toggleSettings} isOpen={showSettings} />
                </div>

                {showSettings && 
                    <Form>
                        {columns.map((column, index) => {
                            return (
                                <React.Fragment key={index + Date.now()}>
                                    <Checkbox
                                        onClick={handleDataItemToggle(column)}
                                        checked={isDataItemChecked(column)}
                                    >
                                        {column} {NAMED_DATA_PROPERTIES[column] && '(' + NAMED_DATA_PROPERTIES[column] + ')'}
                                    </Checkbox>
                                </React.Fragment>
                            )
                        })}
                    </Form>
                }
            </Block>
            <Form>
                <Fieldset>
                    <Label>Формат</Label>
                    <Select options={EXPORT_FORMATS} value={EXPORT_FORMATS[0]} onChange={() => {}} />
                </Fieldset>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    style={{ flex: 1 }}
                    onClick={handleExportSubmit}
                    disabled={!canExport} 
                >
                    Экспорт
                </Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
})