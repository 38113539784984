import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContext, useRef } from 'react'
import { AppContext } from 'src'
import { Block, Button, Centered, Container, Fieldset, Form, Input, Label, Logo, Text } from 'src/components'
import { observer } from 'mobx-react-lite'
import ReCAPTCHA from 'react-google-recaptcha'
import { PUBLIC_RECAPTCHA_KEY, prod } from 'src/http/config'

export const Login = observer(() => {
    const { authStore } = useContext(AppContext)
    const recaptchaRef = useRef(null)

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: Yup.object({
            login: Yup.string().trim().required().min(6).max(30),
            password: Yup.string().trim().required().min(6).max(30),
        }),
        onSubmit: async (values, actions) => {
            // get token if prod
            const token = prod 
                ? await recaptchaRef.current.executeAsync() 
                : '123'
            prod && recaptchaRef.current.reset()

            await authStore.getLogin({ login: values.login, password: values.password, token })
            await authStore.getAuth()
            actions.resetForm()
        },
    })

    const showRecaptcha = prod

    return (
        <Container>
            <Centered>
                <Block large>
                    <Logo small />
                    <Form onSubmit={formik.handleSubmit} id="login-form" autoComplete="true">
                        
                        {showRecaptcha &&
                            <ReCAPTCHA
                                sitekey={PUBLIC_RECAPTCHA_KEY}
                                size='invisible'
                                ref={recaptchaRef}
                            />}

                        {authStore.error && 
                            <Text style={{color: 'var(--orange)'}}>{authStore.error}</Text>}
                        <Fieldset>
                            <Label>Логин</Label>
                            <Input
                                formik
                                placeholder='Введите логин'
                                error={formik.errors.login && formik.touched.login}
                                type='text' name='login' id='login'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.login} disabled={authStore.loading}
                            />
                        </Fieldset>
                        <Fieldset>
                            <Label>Пароль</Label>
                            <Input
                                formik
                                placeholder='Введите пароль'
                                error={formik.errors.password && formik.touched.password}
                                type='password' name='password' id='password'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.password} disabled={authStore.loading}
                            />
                        </Fieldset>
                    </Form>
                    <Button
                        disabled={
                            Object.keys(formik.errors).length ||
                            authStore.loading
                        }
                        form="login-form"
                        type="submit"
                    >
                        Войти
                    </Button>
                </Block>
            </Centered>
        </Container>
    )
})