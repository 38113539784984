import { useContext, useEffect } from 'react'
import { Table, Title } from 'src/components'
import { Main } from 'src/layout/Main/Main'
import { MainLeft } from 'src/layout/Main/MainLeft'
import { MainRight } from 'src/layout/Main/MainRight'
import { EmoloyeeControls } from './EmployeeControls'
import { AppContext } from 'src'
import { DeleteEmployee, EditEmployee } from 'src/modals'
import { observer } from 'mobx-react-lite'
import { SHOW_DATA_USERS } from 'src/assets/data'
import { CloseWorkday } from 'src/modals'

export const Employees = observer(() => {
	const { appStore, employeeStore, modalStore } = useContext(AppContext)

	const empoyeeCreate = (e) => {
		// const data = JSON.parse(e.data)
		appStore.setAlert(`Добавлен новый сотрудник.`)
		employeeStore.adminGetEmployees()
		// call alert
	}

	const employeeUpdate = (e) => {
		const data = JSON.parse(e.data)
		if (employeeStore.employeesAdmin.filter(e => e.id === data.id).length) {
			employeeStore.updateEmployee(data)
			// call alert
		}
	}

	useEffect(() => {
		employeeStore.adminGetEmployees()
	}, [employeeStore.filter]) // eslint-disable-line

	useEffect(() => {
		appStore.ssEvents.addEventListener('employee_create', empoyeeCreate)
		appStore.ssEvents.addEventListener('employee_update', employeeUpdate)

		return () => {
			appStore.ssEvents.removeEventListener('employee_create', empoyeeCreate)
			appStore.ssEvents.removeEventListener('employee_update', employeeUpdate)
		}
	}, []) // eslint-disable-line


	const editEmployeeModal = (id) => {
		employeeStore.selectEmployeeAdmin(id)
		modalStore.setModal(
			<EditEmployee onClose={() => { modalStore.setModal(null); employeeStore.handleSuccess()} } />)
	}
		
	const deleteEmployeeModal = () => modalStore.setModal(
		<DeleteEmployee onClose={() => { modalStore.setModal(null); employeeStore.handleSuccess()} } />)

	const closeWorkdayModal = () => modalStore.setModal(
		<CloseWorkday onClose={() => { modalStore.setModal(null); employeeStore.handleSuccess()} } />)

	return (
		<Main>
			<MainLeft>
				<EmoloyeeControls closeWorkday={closeWorkdayModal} />
			</MainLeft>
			<MainRight>
				<Title>Сотрудники</Title>

				<Table
					data={employeeStore.employeesAdmin}
					showData={SHOW_DATA_USERS}
					onEdit={editEmployeeModal}
					onDelete={deleteEmployeeModal}
				/>

			</MainRight>
		</Main>
	)
})
