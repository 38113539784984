import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContext, useState } from 'react'
import { AppContext } from 'src'
import { Arrow, Block, Button, Checkbox, Fieldset, Form, Input, Label, Text, Title } from 'src/components'
import { NAME_MIN_LEN, NAME_MAX_LEN, LOGIN_MIN_LEN, LOGIN_MAX_LEN, PASSWORD_MIN_LEN, PASSWORD_MAX_LEN } from 'src/assets/data'
import { observer } from 'mobx-react-lite'
import { generatePasswordString, handleCopy } from 'src/assets/utils'
import { SetInactiveConfirmation } from '.'

export const EditEmployee = observer(({ onClose, opened = false }) => {
    const { employeeStore, modalStore } = useContext(AppContext)

    const [showRoles, setShowRoles] = useState(opened)

    const toggleRoles = () => setShowRoles(prev => !prev)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: employeeStore.employeeAdmin.name,
            login: employeeStore.employeeAdmin.login,
            password: '',
            super_admin: employeeStore.employeeAdmin.super_admin,
            admin_of_day: employeeStore.employeeAdmin.admin_of_day,
            user_of_day: employeeStore.employeeAdmin.user_of_day,
            observer: employeeStore.employeeAdmin.observer,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Поле не может быть пустым')
                .min(NAME_MIN_LEN, 'Неверная длина поля')
                .max(NAME_MAX_LEN, 'Неверная длина поля'),
            login: Yup.string().required('Поле не может быть пустым')
                .min(LOGIN_MIN_LEN, 'Неверная длина поля')
                .max(LOGIN_MAX_LEN, 'Неверная длина поля'),
            password: Yup.string().trim()
                .min(PASSWORD_MIN_LEN, 'Неверная длина поля')
                .max(PASSWORD_MAX_LEN, 'Неверная длина поля'),
            super_admin: Yup.boolean(),
            observer: Yup.boolean(),
        }),
        onSubmit: async (values, actions) => {
            const updatedEmployee = {
                id: employeeStore.employeeAdmin.id,
                name: values.name.trim(),
                login: values.login.trim(),
                password: values.password.trim(),
                super_admin: values.super_admin,
                observer: values.observer,
            }
            
            await employeeStore.editEmployee(updatedEmployee)
            if (!employeeStore.error) {
                actions.resetForm()
                onClose()
            }
        },
    })

    const handlePasswordFieldFocus = () => {
        formik.setFieldValue('password', generatePasswordString())
    }

    const isSuperAdminDisabled =
        formik.values.observer ||
        formik.values.user_of_day ||
        employeeStore.loading

    const isOfDay = 
        formik.values.admin_of_day ||
        formik.values.user_of_day

    const isObserverDisabled =
        formik.values.super_admin ||
        isOfDay ||
        employeeStore.loading

    
    const isActive = +employeeStore.employeeAdmin.status === 0

    const handleSetInactive = () => {
        modalStore.setModal(
            <SetInactiveConfirmation employee={employeeStore.employeeAdmin} onClose={() => { 
                modalStore.setModal(
                    <EditEmployee opened={showRoles} onClose={() => { modalStore.setModal(null); employeeStore.handleSuccess()} } />)
                employeeStore.handleSuccess()
            } } />)
    }

    const handleSetActive = async (event) => {
        event.preventDefault()
        await employeeStore.setEmployeeStatus({ id: employeeStore.employeeAdmin.id, status: 0 })
    }

    const canSubmit = 
        employeeStore.employeeAdmin.name !== formik.values.name || 
        employeeStore.employeeAdmin.login !== formik.values.login ||
        formik.values.password.length ||
        employeeStore.employeeAdmin.super_admin !== formik.values.super_admin ||
        employeeStore.employeeAdmin.observer !== formik.values.observer

    return (
        <Block large close={onClose}>
            <Title medium>Редактировать данные сотрудника</Title>
            <Form onSubmit={formik.handleSubmit} id='edit-employee-form'>
                {employeeStore.error &&
                    <Text style={{color: 'var(--orange)'}}>При отправке запроса произошла ошибка: {employeeStore.error}</Text>}
                <Fieldset>
                    <Label required>ФИО</Label>
                    <Input
                        formik
                        placeholder='Введите ФИО сотрудника'
                        error={formik.errors.name && formik.touched.name}
                        type='text' name='name' id='name'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.name} disabled={employeeStore.loading}
                    />
                </Fieldset>
                <Fieldset>
                    <Label required>Логин</Label>
                    <Input
                        formik
                        placeholder='Введите логин'
                        error={formik.errors.login && formik.touched.login}
                        type='text' name='login' id='login'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.login} disabled={employeeStore.loading}
                    />
                </Fieldset>
                <Fieldset>
                    <Label>Новый пароль</Label>
                    <Input
                        copy={handleCopy(formik.values.password)}
                        formik
                        placeholder='Введите пароль'
                        error={formik.errors.password && formik.touched.password}
                        type='text' name='password' id='password'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.password} disabled={employeeStore.loading}
                        onFocus={handlePasswordFieldFocus}
                    />
                </Fieldset>
                
                <div className='flex-sb-c'>
                    <Text medium style={{color: 'var(--grey-01)'}}>Роли</Text>
                    <Arrow onClick={toggleRoles} isOpen={showRoles} />
                </div>
                {showRoles && 
                    <>
                    <div className='flex-column-10'>
                        <Checkbox
                            name='super_admin' id='super_admin'
                            checked={formik.values.super_admin}
                            disabled={isSuperAdminDisabled}
                            onClick={() => formik.setFieldValue('super_admin', !formik.values.super_admin)}
                        >
                            Супер админ
                        </Checkbox>

                        <Checkbox
                            disabled
                            name='admin_of_day' id='admin_of_day'
                            checked={formik.values.admin_of_day}
                            onClick={() => formik.setFieldValue('admin_of_day', !formik.values.admin_of_day)}
                        >
                            Админ дня
                        </Checkbox>

                        <Checkbox
                            disabled
                            name='user_of_day' id='user_of_day'
                            checked={formik.values.user_of_day}
                            onClick={() => formik.setFieldValue('user_of_day', !formik.values.user_of_day)}
                        >
                            Пользователь дня
                        </Checkbox>

                        <Checkbox
                            name='observer' id='observer'
                            checked={formik.values.observer}
                            disabled={isObserverDisabled}
                            onClick={() => formik.setFieldValue('observer', !formik.values.observer)}
                        >
                            Наблюдатель
                        </Checkbox>
                        </div>

                        {isActive 
                            ? <Button disabled={isOfDay} onClick={handleSetInactive} xsmall remove light>Сделать неактивным</Button>
                            : <Button onClick={handleSetActive} xsmall add light>Сделать активным</Button>}
                    </>}
                
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    form='edit-employee-form'
                    type='submit'
                    style={{ flex: 1 }}
                    disabled={!canSubmit || Object.keys(formik.errors).length || employeeStore.loading}
                >
                    Сохранить
                </Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
})