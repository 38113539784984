import { toJS } from 'mobx'

export const handleCopy = (text) => {
    return () => {
        navigator.clipboard.writeText(text).then(() => {})
    }
}

export const handleWhatsapp = (phone) => {
    return 'https://web.whatsapp.com/send?phone=' + phone
}

export const makeQueryParamsStr = (filter) => {
    const query = []
    Object.entries(toJS(filter)).forEach((e) => {
        if (typeof e[1] === 'object' && e[1] !== null) {
            toJS(e[1]).forEach((i) => {
                query.push(`${e[0]}=${i.id}`)
            })
        } else if (e[1]?.length || typeof e[1] === 'number') {
            query.push(`${e[0]}=${e[1]}`)
        } else if (typeof e[1] === 'boolean' && e[1]) {
            query.push(`${e[0]}=${e[1]}`)
        }
    })
    return query.join('&')
}

export const generatePasswordString = (length = 20) => {
    const UPPERCASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const LOWERCASE_CHART = 'abcdefghijklmnopqrstuvwxyz'
    const DIGITS = '01234567890'
    const SYMBOLLS = '@_$!=?'

    const allCharacters = [UPPERCASE_CHARS, LOWERCASE_CHART, DIGITS, SYMBOLLS]

    let passwordString = ''
    for (let i = 0; i < length; i++) {
        const charTypeIndex = Math.floor(Math.random() * allCharacters.length)
        const symbolIndex = Math.floor(Math.random() * allCharacters[charTypeIndex].length)
        const symbol = allCharacters[charTypeIndex][symbolIndex]
        
        passwordString += symbol
    }

    return passwordString
}

export const generateLoginString = (name = 'Иванов Иван Иванович', prefix = 'DDX_') => {
    const russianToEnglishSymbolPairs = {
        'а': 'a',
        'б': 'b',
        'в': 'v',
        'г': 'g',
        'д': 'd',
        'е': 'e',
        'ё': 'e',
        'ж': 'zh',
        'з': 'z',
        'и': 'i',
        'й': 'j',
        'к': 'k',
        'л': 'l',
        'м': 'm',
        'н': 'n',
        'о': 'o',
        'п': 'p',
        'р': 'r',
        'с': 's',
        'т': 't',
        'у': 'u',
        'ф': 'f',
        'х': 'h',
        'ц': 'c',
        'ч': 'ch',
        'ш': 'sh',
        'ъ': '',
        'ы': 'y',
        'ь': '',
        'э': 'a',
        'ю': 'ju',
        'я': 'ja'
    }

    const lowerCaseName = name.toLowerCase()
    const nameItems = lowerCaseName.split(' ')

    let loginItems = []

    for (let i = 0; i < nameItems.length; i++) {
        loginItems[i] = ''
        for (let j = 0; j < nameItems[i].length; j++) {
            const latinChar = russianToEnglishSymbolPairs[nameItems[i][j]] || ''
            loginItems[i] += latinChar
        }
    }

    const lastName = loginItems[0] || ''
    const firstName = loginItems[1] || ''
    const middleName = loginItems[2] || ''

    const loginString = 
        (lastName ? lastName[0].toUpperCase() + lastName.slice(1) : '') +
        (firstName ? firstName[0].toUpperCase() : '') +
        (middleName ? middleName[0].toUpperCase() : '')

    return loginString
    // + '_' + Math.floor(Math.random() * (10000 - 1000) + 1000)
}