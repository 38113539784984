import style from './Menu.module.css'
import { MenuLink } from './MenuLink'


export const Menu = ({ items = [] }) => {
    return (
        <div className={style.menu}>
            {items.map((item, idx) => <MenuLink key={idx + Date.now()} item={item} /> )}
        </div>
    )
}
