import style from './GridData.module.css'

export const GridData = ({ children, oneRow }) => {
    const gridClasses = style.grid_data
        + (oneRow ? ' ' + style.grid_data_one : '')

    return (
        <div className={gridClasses}>
            {children}
        </div>
    )
}
