import { TableItem } from './TableItem'
import { convertNull } from './convertNull'
import style from './Table.module.css'
import { RowControls } from './RowControls'

export const TableRow = ({ rowData = {}, copyData = [], sortData, showData, onEdit, onSort, sorted }) => {
    const rowClasses = style.table_row
        + (!showData ? ' ' + style.table_row_th : '')
    
    const gridColumns = showData
        ? Object.entries(showData).map(item => item[1][1] + 'fr').join(' ') + (onEdit ? ' 1fr' : '')
        : Object.entries(rowData).map(item => item[1][1] + 'fr').join(' ') + (onEdit ? ' 1fr' : '')

    return (
        <div className={rowClasses} style={{gridTemplateColumns: `${gridColumns}`}}>
            {!showData ?
                // rendering header data
                Object.entries(rowData).map((item, idx) => {
                    return (
                        <TableItem
                            key={idx + Date.now()}
                            copyable={copyData.some(i => i === item[0])}
                            sortable={sortData === item[0] && onSort}
                            sorted={sorted}
                        >
                            {item[1][0]}
                        </TableItem>
                    )
                }) :

                // rendering body data
                Object.keys(showData).map((item, idx) => {
                    return (
                        <TableItem
                            key={idx + Date.now()}
                            copyable={copyData.some(i => i === item)}
                            colored={showData[item][2]}
                            whatsapp={item === 'phone'}
                        >
                            {convertNull(rowData[item])}
                        </TableItem>
                    )
                })
            }
            
            {onEdit && (!showData ?
                <TableItem></TableItem>
                :
                    <RowControls edit={() => onEdit(rowData.id)} bitrix={rowData.link !== '-' ? rowData.link : null} />
                )
            }
        </div>
    )
}
