
import { makeAutoObservable } from 'mobx'
import { authApi } from 'src/http'

class AuthStore {

    
    // auth data
    user = {}

    isAuth = null

    isUserOfDay = null
	isAdminOfDay = null
	isSuperAdmin = null
    isObserver = null
    id = null
    login = null
    name = null
    
    // common
    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }


    ////////////////////////////////////////////////////////////////// 
    // GET AUTH (available for everyone)
    //////////////////////////////////////////////////////////////////

    *getAuth() {
        this.loading = true
        const response = yield authApi.getAuth()

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }
        
        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.id = response.data.nid
        this.isUserOfDay = response.data.buser_of_day
        this.isAdminOfDay = response.data.badmin_of_day
        this.isSuperAdmin = response.data.bsuper_admin
        this.isObserver = response.data.bobserver
        this.login = response.data.slogin
        this.name = response.data.sname
        
        this.handleSuccess(response.data)
    }


    ////////////////////////////////////////////////////////////////// 
    // GET LOGIN (available for everyone)
    //////////////////////////////////////////////////////////////////

    *getLogin(user) {
        this.loading = true
        const response = yield authApi.getLogin(user)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.handleSuccess(response.data)
    }


    ////////////////////////////////////////////////////////////////// 
    // GET LOGOUT (available for everyone)
    //////////////////////////////////////////////////////////////////

    *getLogout() {
        this.loading = true
        const response = yield authApi.getLogout()

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.handleError('')
    }


    ////////////////////////////////////////////////////////////////// 
    // COMMON ERROR HANDLER (sets error message and loading = false)
    //////////////////////////////////////////////////////////////////

    handleError(message) {
        this.isAuth = false
        this.isUserOfDay = null
        this.isAdminOfDay = null
        this.isSuperAdmin = null
        this.id = null
        this.login = null
        this.name = null

        this.error = message
        this.loading = false
    }

    ////////////////////////////////////////////////////////////////// 
    // COMMON SUCCESS HANDLER (sets error message and loading = false)
    //////////////////////////////////////////////////////////////////

    handleSuccess(user) {
        this.isAuth = true
        // this.user = user
        // this.isUserOfDay = user.userOfDay
        // this.isAdminOfDay = user.adminOfDay
        // this.isSuperAdmin = user.superAdmin
        this.loading = false
        this.error = ''
    }


}

export default AuthStore