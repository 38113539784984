import { makeAutoObservable } from 'mobx'
import { API_STREAM } from 'src/http/config'

const INIT_SETTINGS = {
    skip_workday_open: { id: 2, name: 'Нет', value: false },
    pagination_view: { id: 3, name: 'Снизу и сверху' },
}

const SAVED_SETTINGS = JSON.parse(localStorage.getItem('crm_settings'))

class AppStore {

    // app settings
    settings = SAVED_SETTINGS || INIT_SETTINGS

    // sse
    ssEvents = null

    // alert
    alert = null
    alertTimeout = null

    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

    setSettings(settings) {
        this.settings = settings
        this.saveSettings()
    }

    saveSettings() {
        localStorage.setItem('crm_settings', JSON.stringify(this.settings))
    }

    // SSE CONTROLS
    connectSSE() {
        this.ssEvents = new EventSource(API_STREAM, { withCredentials: true })

        // listen on open
        this.ssEvents.onopen = (e) => {
            console.log('sse opened')
        }

        // listen to error
        this.ssEvents.onerror = (e) => {
            console.log('sse error')
        }
    }

    disconnectSSE() {
        if (this.ssEvents) {
            this.ssEvents.close()
        }
    }

    // ALERT CONTROLS
    setAlert(message, timeout = 5000) {
        if (this.alertTimeout) {
            clearTimeout(this.alertTimeout)
        }

        this.alert = message
        this.alertTimeout = setTimeout(() => {
            this.clearAlert()
        }, timeout)
    }

    clearAlert() {
        this.alert = null
    }

}

export default AppStore