import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Block, Button, Fieldset, Form, Input, Label, Text, Title } from 'src/components'
import { useContext } from 'react'
import { AppContext } from 'src'
import { NAME_MIN_LEN, NAME_MAX_LEN, LOGIN_MIN_LEN, LOGIN_MAX_LEN, PASSWORD_MIN_LEN, PASSWORD_MAX_LEN, ADMIN_SELECT_LIST } from 'src/assets/data'
import { observer } from 'mobx-react-lite'
import { generateLoginString, generatePasswordString, handleCopy } from 'src/assets/utils'


export const AddEmployee = observer(({ onClose }) => {
    const { employeeStore } = useContext(AppContext)

    const generatedPassword = generatePasswordString()

    const formik = useFormik({
        initialValues: {
            name: '',
            login: '',
            password: generatedPassword,
            super_admin: ADMIN_SELECT_LIST[1]
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Поле не может быть пустым')
                .min(NAME_MIN_LEN, 'Неверная длина поля')
                .max(NAME_MAX_LEN, 'Неверная длина поля'),
            login: Yup.string().required('Поле не может быть пустым')
                .min(LOGIN_MIN_LEN, 'Неверная длина поля')
                .max(LOGIN_MAX_LEN, 'Неверная длина поля'),
            password: Yup.string().required('Поле не может быть пустым')
                .min(PASSWORD_MIN_LEN, 'Неверная длина поля')
                .max(PASSWORD_MAX_LEN, 'Неверная длина поля'),
            super_admin: Yup.mixed().test('super_admin', 'super_admin', (sa) => sa?.name),
        }),
        onSubmit: async (values, actions) => {
            const newEmployee = {
                name: values.name.trim(),
                login: values.login.trim(),
                password: values.password.trim(),
                super_admin: false,
                user_of_day: false,
                admin_of_day: false
            }

            await employeeStore.createEmployee(newEmployee)
            if (!employeeStore.error) {
                actions.resetForm()
                onClose()
            }
        },
    })

    const customHandleChange = event => {
        const { value: name } = event.target;
        const generatedLogin = name ? generateLoginString(name) : ''
        formik.setFieldValue('name', name)
        formik.setFieldValue('login', generatedLogin)
      }

    return (
        <Block large close={onClose}>
            <Title medium>Новый сотрудник</Title>
            <Form onSubmit={formik.handleSubmit} id='create-employee-form'>
                {employeeStore.error &&
                    <Text style={{color: 'var(--orange)'}}>При отправке запроса произошла ошибка: {employeeStore.error}</Text>}
                <Fieldset>
                    <Label required>ФИО</Label>
                    <Input
                        formik
                        placeholder='Введите ФИО сотрудника'
                        error={formik.errors.name && formik.touched.name}
                        type='text' name='name' id='name'
                        onChange={customHandleChange} onBlur={formik.handleBlur}
                        value={formik.values.name} disabled={employeeStore.loading}
                    />
                </Fieldset>
                <Fieldset>
                    <Label required>Логин</Label>
                    <Input
                        copy={handleCopy(formik.values.login)}
                        formik
                        placeholder='Введите логин'
                        error={formik.errors.login && formik.touched.login}
                        type='text' name='login' id='login'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.login} disabled={employeeStore.loading}
                    />
                </Fieldset>
                <Fieldset>
                    <Label required>Пароль</Label>
                    <Input
                        copy={handleCopy(formik.values.password)}
                        formik
                        placeholder='Введите пароль'
                        error={formik.errors.password && formik.touched.password}
                        type='text' name='password' id='password'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.password} disabled={employeeStore.loading}
                    />
                </Fieldset>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    form='create-employee-form'
                    type='submit' style={{ flex: 1 }}
                    disabled={Object.keys(formik.errors).length || !Object.keys(formik.touched).length || employeeStore.loading}
                >
                    Добавить
                </Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
})