import React, { useContext, useEffect } from 'react'
import { Alert, Export, Limits, Pagination, Table, Text, Title } from 'src/components'
import { Main } from 'src/layout/Main/Main'
import { MainLeft } from 'src/layout/Main/MainLeft'
import { MainRight } from 'src/layout/Main/MainRight'
import { LeadsFilter } from './LeadsFilter'
import { LeadsWorkday } from './LeadsWorkday'
import { AppContext } from 'src'
import { DeleteUserOfDay, EditLead, AddUserOfDay } from 'src/modals'
import { ExportLeads } from 'src/modals/ExportLeads'
import { observer } from 'mobx-react-lite'
import { SHOW_DATA_LEADS_ADMIN, SHOW_DATA_LEADS, COPY_DATA_LEADS, SORT_DATA_ITEM } from 'src/assets/data'


export const Leads = observer(() => {
	const { authStore, leadStore, modalStore, appStore, employeeStore, workdayStore } = useContext(AppContext)

	const leadCreate = (event) => {
		const data = JSON.parse(event.data)

		if (authStore.isSuperAdmin || (+authStore.id === +data.employee_id)) {
			leadStore.increaseCounter()
		}
	}

	const leadUpdate = (event) => {
		const { updatedLeads } = JSON.parse(event.data)
		leadStore.updateLead(updatedLeads)
	}

	useEffect(() => {
		leadStore.getLeads()
	}, [leadStore.filter, workdayStore.is_workday_opened]) // eslint-disable-line

	useEffect(() => {
		leadStore.getEmployeeList()
		leadStore.getSourceList()

		appStore.ssEvents.addEventListener('lead_create', leadCreate)
		appStore.ssEvents.addEventListener('lead_update', leadUpdate)

		return () => {
			appStore.ssEvents.removeEventListener('lead_create', leadCreate)
			appStore.ssEvents.removeEventListener('lead_update', leadUpdate)
		}
	}, []) // eslint-disable-line


	const editLeadModal = (id) => {
		leadStore.selectLead(id)
		modalStore.setModal(
			<EditLead onClose={() => { modalStore.setModal(null); leadStore.handleSuccess()} }/>)
	}

	const handleSortToggle = () => leadStore.toggle_sort()
		
	const exportLeadsModal = () => modalStore.setModal(
		<ExportLeads onClose={() => { modalStore.setModal(null); leadStore.handleSuccess()} }/>)

	const addUserOfDayModal = () => modalStore.setModal(
		<AddUserOfDay onClose={() => { modalStore.setModal(null); employeeStore.handleSuccess()} } />)

	const deleteUserOfDayModal = (employee) => modalStore.setModal(
		<DeleteUserOfDay onClose={() => { modalStore.setModal(null); employeeStore.handleSuccess()} } employee={employee} />)

	const paginationViewValue = appStore.settings.pagination_view.id

	const showPaginationOnTop = paginationViewValue === 3 || paginationViewValue === 2
	const showPaginationOnBottom = paginationViewValue === 3 || paginationViewValue === 1

	const showLeads = leadStore.leads.length > 0

	const newLeadsMessage = leadStore.new_lead_counter > 0 && 'Новые заявки: ' + leadStore.new_lead_counter
	const handleMessageClose = () => leadStore.close_message()
	const handleNewLeadsShow = () => leadStore.show_new_leads()

	const showLeadEmployee = authStore.isSuperAdmin || authStore.isObserver
	const tableData = showLeadEmployee ? SHOW_DATA_LEADS_ADMIN : SHOW_DATA_LEADS

	const handleLeadEdit = authStore.isObserver ? null : editLeadModal
		
    return (
        <Main>
            <MainLeft>
                <LeadsFilter/>
                <LeadsWorkday
					addUserOfDay={addUserOfDayModal}
					deleteUserOfDay={deleteUserOfDayModal}
				/>
            </MainLeft>

            <MainRight>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{/* <Title>Заявки <span style={{fontSize: 30}}>({leadStore.records})</span></Title> */}
					<Title>Заявки</Title>
					{/* <Text style={{ color: 'var(--grey-01)', fontWeight: 700 }}>Всего заявок: {leadStore.records}</Text> */}
					{showLeads &&
						<Export onClick={exportLeadsModal} />}
				</div>

				<div className='flex-sb-c'>

					{/* отображается только если есть записи */}
					{showLeads && <>

						{showPaginationOnTop ?
							<Pagination
								page={leadStore.filter.page}
								setPage={(p) => leadStore.set_page(p)}
								pages={leadStore.pages}
							/> : <div></div>}

						<Text style={{ color: 'var(--grey-01)', fontWeight: 700 }}>Всего заявок: {leadStore.records}</Text>
					</>}
					
				</div>

				<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
					{newLeadsMessage &&
						<Alert
							message={newLeadsMessage}
							onClose={handleMessageClose}
							showNewLeads={handleNewLeadsShow}
						/>}

					<Table
						data={leadStore.leads}
						showData={tableData}
						copyData={COPY_DATA_LEADS}
						sortData={SORT_DATA_ITEM}
						onEdit={handleLeadEdit}
						onSort={handleSortToggle}
						sorted={leadStore.filter.sort}
					/>
				</div>

				{showPaginationOnBottom &&
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>

						{/* отображается только если есть записи */}
						{showLeads && <>
							<Pagination
								page={leadStore.filter.page}
								setPage={(p) => leadStore.set_page(p)}
								pages={leadStore.pages}
							/>
							
							<Limits
								setLimit={(l) => leadStore.set_limit(l)}
								limit={leadStore.filter.limit}
								limits={leadStore.limits}
							/>
						</>}

					</div>}
            </MainRight>
        </Main>
    )
})