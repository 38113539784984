import axios from 'axios'
import { makeQueryParamsStr } from 'src/assets/utils'


export const getAnalytics = async (filter) => {
    try {
        const response = await axios.get('https://api.play-ddx.ru/minicrm/get-analytics?' + makeQueryParamsStr(filter))
        return response
    } catch (err) {
        return err.response
    }
}