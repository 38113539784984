import style from './Tag.module.css'

export const TagButton = ({ onDelete }) => {
    return (
        <svg onClick={onDelete} className={style.tag_button} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 4L4 12' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M4 4L12 12' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    )
}
