import { makeAutoObservable, toJS } from 'mobx'
import { analyticsApi } from 'src/http'

const today = new Date()
const sevenDaysAgo = new Date()

sevenDaysAgo.setDate(today.getDate() - 6)

const INIT_FILTER = {
    clubs: [],
    dateFrom: sevenDaysAgo.toLocaleDateString().split('.').reverse().join('-'),
    dateTo: today.toLocaleDateString().split('.').reverse().join('-'),
}

const SAVED_FILTER = JSON.parse(localStorage.getItem('crm_analytics_filter'))

class AnalyticsStore {

    // analytics data
    _data = null
    clubs_list = null

    // analytics filtering params
    filter = SAVED_FILTER || INIT_FILTER

    // common
    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }


    ////////////////////////////////////////////////////////////////// 
    // GET ANALYTICS
    //////////////////////////////////////////////////////////////////

    *getAnalytics() {
        this.loading = true
        const response = yield analyticsApi.getAnalytics({ dateFrom: this.filter.dateFrom, dateTo: this.filter.dateTo })

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        console.log(response, response.data)
        this._data = response.data.leads
        this.clubs_list = response.data.clubs.sort().map((club, index) => ({ id: index, name: club }))
        this.handleSuccess()
    }

    get leads() {
        if (this.filter.clubs.length) {
            const newObject = {}
            Object.keys(this._data)
                .filter(currentKey => this.filter.clubs.some(currentClub => currentClub.name === currentKey))
                .sort()
                .forEach(currentKey => newObject[currentKey] = toJS(this._data[currentKey]))
            return newObject
        }
        return this._data
    }


    set_filter(filter) {
        this.filter = filter
        this.saveFilter()
    }

    reset_filter() {
        this.filter = {
            ...INIT_FILTER,
        }
        this.saveFilter()
    }

    saveFilter() {
        localStorage.setItem('crm_analytics_filter', JSON.stringify({ ...this.filter }))
    }

    ////////////////////////////////////////////////////////////////// 
    // COMMON ERROR HANDLER (sets error message and loading = false)
    //////////////////////////////////////////////////////////////////

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default AnalyticsStore