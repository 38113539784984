import { useContext } from "react"
import { AppContext } from "src"
import { Block, Button, Text, Title } from "src/components"

export const SetInactiveConfirmation = ({ onClose, employee }) => {
    const { employeeStore } = useContext(AppContext)

    const handleSubmit = async () => {
        await employeeStore.setEmployeeStatus({ id: employee.id, status: 1 })
        if (!employeeStore.error.length) {
            onClose()
        }
    }

    return (
        <Block large close={onClose}>
            <Title medium>Вы действительно хотите сделать сотрудника 
                <span style={{color: 'var(--orange)'}}> {employee.name}</span> неактивным?
            </Title>
            {employeeStore.error &&
                <Text style={{ color: 'var(--orange)' }}>При отправке запроса произошла ошибка: {employeeStore.error}</Text>}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button onClick={handleSubmit} style={{ flex: 1 }}>Подтвердить</Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
}