import { useContext } from "react"
import { AppContext } from "src"
import { Block, Button, Text, Title } from "src/components"

export const CloseWorkday = ({ onClose }) => {
    const { workdayStore } = useContext(AppContext)

    const handleSubmit = async () => {
        await workdayStore.closeWorkday()
        if (!workdayStore.error.length) {
            onClose()
        }
    }
    return (
        <Block large close={onClose}>
            <Title medium>Вы действительно хотите завершить рабочий день?</Title>
            {workdayStore.error &&
                <Text style={{ color: 'var(--orange)' }}>При отправке запроса произошла ошибка: {workdayStore.error}</Text>}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button onClick={handleSubmit} style={{ flex: 1 }}>Завершить</Button>
                <Button style={{ flex: 1 }} light onClick={onClose}>Отмена</Button>
            </div>
        </Block>
    )
}