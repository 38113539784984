import { handleCopy } from 'src/assets/utils'
import style from './InfoTable.module.css'
import { InfoTableCopyBtn } from './InfoTableCopyBtn'

export const InfoTable = ({ data, showData, copyData = [] }) => {
    const isArray = data?.constructor?.name === 'Array'
    const dataItems = isArray
        ? data
        : Object.entries(showData)

    return (
        <div className={style.info_table}>
            {dataItems.map((item, idx) => {
                return (
                    <div className={style.info_table_item}
                        key={idx + Date.now()}
                    >
                        <div className={style.info_table_label}>
                            {isArray ? item[0] : item[1]}
                        </div>
                        <div className={style.info_table_text}>
                            {copyData.some(i => i === item[0]) ?
                                <InfoTableCopyBtn onClick={handleCopy(data[item[0]])} /> : <div></div>}
                            <p>{isArray ? item[1] : data[item[0]] || '-'}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}