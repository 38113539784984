import style from './Overlay.module.css'

export const Overlay = ({ children, ...props }) => {
    return (
        <div {...props} className={style.overlay}>
            <div className={style.overlay_scroll_y}>
                {children}
            </div>
        </div>
    )
}