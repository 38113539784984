import { authApi } from './config'

export const getAuth = async () => {
    try {
        const response = await authApi.get('/auth')
        return response
    } catch (err) {
        return err.response
    }

}

export const getLogin = async (user) => {
    try {
        const response = await authApi.post('/auth/login', user)
        return response
    } catch (err) {
        return err.response
    }
    
}

export const getLogout = async () => {
    try {
        const response = await authApi.post('/auth/logout')
        return response
    } catch (err) {
        return err.response
    }
}
