import style from './Block.module.css'

export const BlockCloseBtn = ({ onClose }) => {
    return (
        <button onClick={onClose} className={style.block_close_btn}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M18 6L6 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M6 6L18 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
        </button>
    )
}
