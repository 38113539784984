import style from './Table.module.css'

export const SortBtn = ({ sorted = false, ...props }) => {
    const sortClasses = style.sort_btn
        + (sorted ? ' ' + style.sort_btn_asc : '')

    return (
        <svg {...props} className={sortClasses} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g id="Edit / Sort_Descending">
                    <path id="Vector" d="M4 17H16M4 12H13M4 7H10M18 13V5M18 5L21 8M18 5L15 8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
            </g>
        </svg>
    )
}
