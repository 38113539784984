import { useEffect, useRef, useState } from 'react'
import { ProfileArrow } from './ProfileArrow'
import { ProfileIcon } from './ProfileIcon'
import { ProfileName } from './ProfileName'
import style from './ProfileData.module.css'
import { ProfileMenu } from './ProfileMenu'

export const ProfileData = ({ login, handleSettings, handleLogout }) => {
    const rootEl = useRef(null)
    const [opened, setOpened] = useState(false)
    const toggleOpened = () => setOpened(prev => !prev)

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setOpened(false)
        if (opened) document.addEventListener('click', onClick)
        return () => { opened && document.removeEventListener('click', onClick) }
    })

    return (
        <div ref={rootEl} className={style.profile_data} onClick={toggleOpened}>
            <div>
                <ProfileIcon />
                <ProfileName name={login} />
                <ProfileArrow opened={opened}/>
            </div>
            {opened &&
                <ProfileMenu setOpened={setOpened} opened={opened} >
                    <button onClick={handleSettings}>Настройки</button>
                    <button onClick={handleLogout}>Выход</button>
                </ProfileMenu>}
        </div>
    )
}
