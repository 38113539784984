import style from './Button.module.css'

export const Button = ({ children, light, small, xsmall, add, remove, back, disabled, ...props }) => {
    const buttonClasses = style.btn 
        + (light ? ' ' + style.btn_light : '')
        + (small ? ' ' + style.btn_small : '')
        + (xsmall ? ' ' + style.btn_x_small : '')
        + (disabled ? ' ' + style.btn_disabled : '')
        + (back ? ' ' + style.btn_return : '')

    return (
        <button
            {...props}
            disabled={disabled} 
            className={buttonClasses}
        >
            {add &&
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path fill='white' fillRule='evenodd' clipRule='evenodd' d='M12 5C12.5523 5 13 5.44772 13 6L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 6C11 5.44772 11.4477 5 12 5Z' />
                    <path fill='white' fillRule='evenodd' clipRule='evenodd' d='M19 12C19 12.5523 18.5523 13 18 13L6 13C5.44771 13 5 12.5523 5 12C5 11.4477 5.44771 11 6 11L18 11C18.5523 11 19 11.4477 19 12Z' />
                </svg>}

            {remove &&
                <svg style={{width: 20, height: 20}} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path stroke='var(--grey-01)' d='M18 6L6 18' strokeWidth='2.3' strokeLinecap='round' strokeLinejoin='round' />
                    <path stroke='var(--grey-01)' d='M6 6L18 18' strokeWidth='2.3' strokeLinecap='round' strokeLinejoin='round' />
                </svg>}

            {back &&
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M10 6L4 12M4 12L10 18M4 12H19' stroke='#676785' strokeWidth='2' strokeLinecap='round'/>
                </svg>}

            <span>{children}</span>
        </button>
    )
}