import { Container } from 'src/components'
import style from './Main.module.css'

export const Main = ({ children }) => {
    return (
        <Container>
            <div className={style.main}>
                {children}
            </div>
        </Container>
    )
}